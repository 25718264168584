<template>
  <div class="w-full h-full items-center flex justify-center">
    <div class="centered-content">
      <div class="header text-center p-1">
        <h1>RIVERNET.LK</h1>
        <h1>Early Warning System</h1>
        <h2>Inclusive access to timely information</h2>
      </div>

      <div class="page-content m-auto text-center p-1">
        <h2>Water Levels of Rivers</h2>
        <div>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="relief-primary"
            class="w-4/5 m-1"
            @click="goTo('_kaluganga-overview')"
          >
            Kalu Ganga
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="relief-primary"
            class="w-4/5 m-1"
            @click="goTo('_kelanigana-overview')"
          >
            Kelani Ganga
          </b-button>
        </div>
      </div>
    </div>
    <add-to-home-prompt />

    <!-- <div
      class="notice"
      :class="{ active: noticeActive }"
    >
      <div class="notice-wrapper">
        <div>
          <h4>කාරුණික දැන්වීමයි!</h4>
          <p>
            දැනට රටේ පවතින විදුලිබලය කපාහැරීම් නිසා GSM ජාලයට හා ලෙවල් සංඥා
            පද්ධති ක්‍රියා කාරිත්වයට දැඩි බලපෑම් එල්ලවී ඇත. ඒ පිලිබඳ අපගේ බලවත්
            කණගාටුව.
          </p>
        </div>

        <div>
          <h4>Kind notice!</h4>
          <p>
            The prevailing power cuts in the country has affected the proper
            functioning of GSM network and level signalling systems. Sorry for
            the inconvenience.
          </p>
        </div>
        <button
          class="notice-button"
          @click="noticeActive = false"
        >
          OK
        </button>
      </div>
    </div> -->
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AddToHomePrompt from "@/@leecom/components/AddToHomePrompt.vue";

export default {
  components: {
    BButton,
    AddToHomePrompt,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      noticeActive: true,
    };
  },
  methods: {
    goTo(key) {
      if (key == "_kaluganga-overview") {
        window.location.href = "./../_kaluganga-overview";
      }

      if (key == "_kelanigana-overview") {
        window.location.href = "./../_salford";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1,
h2 {
  color: $dark;
}

.page-content {
  max-width: 320px;
}

.notice {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #00000045;
  display: none;
}

.notice.active {
  display: block;
}

.notice-wrapper {
  max-width: 500px;
  background: #fff;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notice-wrapper div {
  margin: 15px;
}

.notice-wrapper h4,
.notice-wrapper p {
  text-align: center;
}

.notice-button {
  background-color: #1a73e8;
  color: white;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100px;
  margin: 0 auto 10px;
  display: block;
}
</style>
