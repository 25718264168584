<template>
  <div
    class="a2hs-message a2hs-overlay-container"
    v-if="promptVisible"
  >
    <div
      id="toast-container"
      class="toast-top-left toast-container"
    >
      <div
        class="toast-info ngx-toastr ng-trigger ng-trigger-flyInOut"
        style="opacity: 1; cursor: default;"
      >
        <div
          class="toast-title ng-star-inserted"
          style
        >
          <feather-icon
            icon="DownloadCloudIcon"
            size="32"
            class="downloadIcon"
          />
          Install Rivernet.lk App now?
        </div>

        <div
          class="toast-message ng-star-inserted"
          style
        >
          <div class="actions">
            <button
              type="button"
              class="btn btn-primary a2hs-install"
              @click="install"
            >
              Install
            </button>

            <button
              type="button"
              class="btn btn-link a2hs-cancel"
              @click="dismiss"
            >
              Dismiss
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deferredPrompt: null,
      promptVisible: false,
    };
  },
  methods: {
    async dismiss() {
      this.promptVisible = false;
      this.deferredPrompt = null;
      this.rememberDecision(false);
    },
    async install() {
      this.promptVisible = false;
      this.deferredPrompt.prompt();
    },
    rememberDecision(decision) {
      const expiresAt = this.$moment().add(10, "days");
      localStorage.setItem(
        "allow_to_install_expired_at",
        JSON.stringify(expiresAt.valueOf())
      );
      localStorage.setItem("allow_to_install", decision);
    },
    getPreviousDecision() {
      const expiration = localStorage.getItem("allow_to_install_expired_at");
      const expiresAt = JSON.parse(expiration);

      if (this.$moment().isBefore(this.$moment(expiresAt))) {
        return JSON.parse(localStorage.getItem("allow_to_install"));
      } else {
        return true;
      }
    },
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      console.log(e, "beforeinstallprompt");
      this.deferredPrompt = e;

      setTimeout(() => {
        if (this.getPreviousDecision()) {
          console.log("A2HS:", "No Previous Decision Found.");
          this.promptVisible = true;
        }
      }, 500);
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
      console.log("appinstalled");
    });
  },
};
</script>

<style lang="scss" scoped>
.a2hs-overlay-container .actions {
  margin-top: 10px;
}

.a2hs-cancel:hover,
.a2hs-cancel:focus {
  text-decoration: none;
}
.a2hs-cancel {
  float: right;
  color: #1a73e8;
}

.a2hs-install {
  float: right;
  background-color: #1a73e8;
}
.a2hs-overlay-container .toast-container .ngx-toastr {
  border: 1px solid rgba(0, 147, 221, 0.28);
}
.a2hs-overlay-container .toast-container .ngx-toastr::before {
  background-color: #1a73e8;
}

.downloadIcon {
  color: #1a73e8;
  position: absolute;
  left: 12px;
}

.toast-container {
  pointer-events: def;
  position: fixed;
  z-index: 999999;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
  -webkit-box-shadow: 0 0 12px #999999;
  -moz-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #fff;
  background-color: #fff;
  -webkit-box-shadow: 1px 7px 14px -5px rgb(0 0 0 / 20%);
  -moz-box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 14px -5px rgb(0 0 0 / 20%);
  background-image: none;
  border: 1px solid #ff0b012e;
  border: 1px solid #0093dd47;
}

.toast-container .ngx-toastr::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  background-color: #000;
}

.toast-title {
  color: #000;
  font-weight: 500;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  cursor: pointer;
}
.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}
</style>